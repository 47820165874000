import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isTokenExpired } from "./utility.js";
import useToken from "./useToken.js";

export default function useAutoLogout(){
  const { token, setToken } = useToken();
  let navigate = useNavigate();

  useEffect(() => {
    if (token && isTokenExpired(token)) {
      console.log("Token expired. Logging out...");
      setToken(null); // Clear the token
      navigate("/login"); // Redirect to login page
    }
  }, [token, setToken, navigate]);
};
