import React from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { RadioGroup, Select, TextField } from "formik-mui";
import {
  Box,
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
} from "@mui/material";
import * as Yup from "yup";

const StepOne = () => {
  const { values } = useFormikContext(); // Access Formik state
  const isEndOfLoanPeriodDisabled = values.status !== "Pret";

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Field
          type='text'
          id='serialNumberPopmetre'
          name='serialNumberPopmetre'
          label='N° série du pOpmètre'
          component={TextField}
          disabled={false}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          type='text'
          id='serialNumberSensors'
          name='serialNumberSensors'
          label='N°série capteurs (si envoi de nouveaux capteurs)'
          component={TextField}
          disabled={false}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl required fullWidth variant='outlined'>
          <Field
            id='status'
            name='status'
            label='Statut'
            component={Select}
            variant='outlined'
            required
          >
            <MenuItem value='Pret'>Pret</MenuItem>
            <MenuItem value='Vente'>Vente</MenuItem>
            <MenuItem value='Test'>Test</MenuItem>
            <MenuItem value='Don'>Don</MenuItem>
          </Field>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          type='date'
          id='endOfLoanPeriod'
          name='endOfLoanPeriod'
          component={TextField}
          label='Fin de période de prêt'
          disabled={isEndOfLoanPeriodDisabled}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          type='date'
          id='releaseDate'
          name='releaseDate'
          label='Date de sortie'
          component={TextField}
          disabled={false}
          required
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          type='text'
          id='invoiceNumber'
          name='invoiceNumber'
          label='N°FACT ou de BL'
          component={TextField}
          disabled={false}
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          type='date'
          id='invoiceDate'
          name='invoiceDate'
          label='Date Facture'
          component={TextField}
          disabled={false}
          required
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Field
          type='text'
          id='folderLocation'
          name='folderLocation'
          label='Emplacement dossier'
          component={TextField}
          disabled={false}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl component='fieldset'>
          <FormLabel
            required
            component='legend'
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "green", // Change border color when focused
                },
              },
            }}
          >
            {" "}
            Extension de garantie 3 ans{" "}
          </FormLabel>
          <Field row component={RadioGroup} name='warrantyExtension'>
            <FormControlLabel
              value='oui'
              control={<Radio color='secondary' />}
              label='Oui'
            />
            <FormControlLabel
              value='non'
              control={<Radio color='secondary' />}
              label='Non'
            />
          </Field>
          <ErrorMessage name='warrantyExtension'>
            {(msg) => <div className='errorMessage'>{msg}</div>}
          </ErrorMessage>{" "}
        </FormControl>
      </Grid>
    </Grid>
  );
};

StepOne.title = "Informations pOpmetre";

StepOne.initialValues = {
  serialNumberPopmetre: "",
  serialNumberSensors: "",
  status: "",
  endOfLoanPeriod: "",
  releaseDate: "",
  folderLocation: "",
  invoiceNumber: "",
  invoiceDate: "",
  warrantyExtension: "",
};

StepOne.validationSchema = Yup.object({
  serialNumberPopmetre: Yup.string(),
  serialNumberSensors: Yup.string(),
  status: Yup.string().required("Champ Obligatoire"),
  endOfLoanPeriod: Yup.string().when("status", {
    is: (value) => value === "Pret",
    then: (schema) =>
      schema.required(
        "La fin de la période de prêt est obligatoire lorsqu'un popmetre est prêté."
      ),
    otherwise: (schema) => schema,
  }),
  releaseDate: Yup.date().required("Champ Obligatoire"),
  folderLocation: Yup.string(),
  invoiceNumber: Yup.string().required("Champ Obligatoire"),
  invoiceDate: Yup.date().required("Champ O bligatoire"),
  warrantyExtension: Yup.string()
    .oneOf(["non", "oui"], 'Vous devez sélectionner "Oui" ou "Non".')
    .required("Vous devez sélectionner 'Oui' ou 'Non'"),
});
export default StepOne;
