export const isTokenExpired = (token) => {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      const now = Date.now().valueOf() / 1000;
      if (payload.exp && payload.exp < now) {
        return true;
      }
    } catch (e) {
      console.error("Error checking token expiration", e);
      return false;
    }
    return false;
  };