import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import CustomDialog from "../../components/CustomDialog";
import { useTheme } from "@mui/material";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import DeleteIcon from "@mui/icons-material/Delete";
import useToken from "../../useToken";
import axios from "axios";

const Popmetres = () => {
  const { token } = useToken();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [data, setData] = useState([]);

  //// For dialog
  const [dialogData, setDialogData] = useState({
    open: false,
    title: "",
    content: "",
    actionText: "Close",
    isSuccess: false,
  });

  const handleCloseDialog = () => {
    setDialogData((prevData) => ({
      ...prevData,
      open: false,
    }));
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL_API}/api/popmetre/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, []);

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "serialNumberPopmetre",
      headerName: "N° série pOpmètre",
      flex: 1,
      minWidth: 180,
      headerAlign: "left",
      editable: true,
      align: "left",
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "serialNumberSensors",
      headerName: "N°série capteurs",
      flex: 1,
      minWidth: 130,
      headerAlign: "left",
      align: "left",
      editable: true,
      // cellClassName: "name-column--cell",
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Statut",
      flex: 1,
      minWidth: 70,
      editable: true,
    },
    {
      field: "endOfLoanPeriod",
      headerName: "Date de retour attendue",
      flex: 1,
      minWidth: 150,
      // type: 'date',  // cause old data contains text values we can't set type to date
      editable: true,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "realReturnDate",
      headerName: "Date de retour réel",
      flex: 1,
      minWidth: 150,
      //type: 'date',
      editable: true,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "client",
      headerName: "Client",
      flex: 1,
      minWidth: 130,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          {params.value}
        </div>
      ),
      editable: true,
    },
    {
      field: "clientEmail",
      headerName: "Email Client",
      flex: 1,
      minWidth: 180,
      editable: true,
    },
    {
      field: "user",
      headerName: "Utilisateur",
      flex: 1,
      minWidth: 130,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          {params.value}
        </div>
      ),
      editable: true,
    },
    {
      field: "userEmail",
      headerName: "Email Utilisateur",
      flex: 1,
      minWidth: 180,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          {params.value}
        </div>
      ),
      editable: true,
    },
    {
      field: "activity",
      headerName: "Activité",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          {params.value}
        </div>
      ),
      editable: true,
    },
    {
      field: "trainingDate",
      headerName: "Date de formation à l'usage",
      flex: 1,
      minWidth: 100,
      editable: true,
    },
    {
      field: "phone1",
      headerName: "N° Téléphone 1",
      flex: 1,
      minWidth: 100,
      editable: true,
    },
    {
      field: "phone2",
      headerName: "N° Téléphone 2",
      flex: 1,
      minWidth: 100,
      editable: true,
    },
    {
      field: "clinicalStudy",
      headerName: "Etude Clinique",
      flex: 1,
      minWidth: 100,
      editable: true,
    },
    {
      field: "postalAddress",
      headerName: "Adresse Postale",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          {params.value}
        </div>
      ),
      editable: true,
    },
    {
      field: "country",
      headerName: "Pays",
      minWidth: 90,
      editable: true,
    },
    {
      field: "releaseDate",
      headerName: "Date de Sortie",
      minWidth: 100,
      //type: 'date',
      editable: true,
    },
    {
      field: "folderLocation",
      headerName: "Emplacement Dossier",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          {params.value}
        </div>
      ),
      editable: true,
    },
    {
      field: "invoiceNumber",
      headerName: "N°FACT ou de BL",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          {params.value}
        </div>
      ),
      editable: true,
    },
    {
      field: "invoiceDate",
      headerName: "Date Facture",
      flex: 1,
      //type: 'date',
      minWidth: 100,
      editable: true,
    },
    {
      field: "warrantyExtension",
      headerName: "Extension de garantie 3 ans",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          {params.value}
        </div>
      ),
      editable: true,
    },
    {
      field: "softwareVersion",
      headerName: "Version Logicielle",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          {params.value}
        </div>
      ),
      editable: true,
    },
    {
      field: "firmwareVersion",
      headerName: "Firmware",
      minWidth: 100,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          {params.value}
        </div>
      ),
      editable: true,
    },
    {
      field: "softwareUpdateDec2020",
      headerName: "MAJ Logiciel Déc 2020",
      minWidth: 100,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          {params.value}
        </div>
      ),
      editable: true,
    },
    {
      field: "last_software_version_downloaded",
      headerName: "Dernière version logicielle",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "date_last_download",
      headerName: "Date de Téléchargement Dernière Version log",
      flex: 1,
      minWidth: 100,
      type: 'dateTime',
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "additionalInformation",
      headerName: "Information Supplémentaire",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          {params.value}
        </div>
      ),
      editable: true,
    },
    {
      field: "Envoi du lien",
      type: "actions",
      headerName: "Envoi du lien",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<ForwardToInboxIcon />}
          label='envoi du lien'
          onClick={handleSendEmailClick(params.row.clientEmail)}
        />,
      ],
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Supprimer",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label='Supprimer'
          onClick={handleDeleteClick(params.id)}
        />,
      ],
    },
  ];

  const columnVisibilityModel = {
    warrantyExtension: false,
    folderLocation: false,
    invoiceDate: false,
    phone2: false,
    trainingDate: false,
    postalAddress: false,
    clientEmail: false,
    userEmail: false,
    clinicalStudy: false,
    additionalInformation: false,
    serialNumberSensors: false,
    endOfLoanPeriod: true,
    realReturnDate: false,
    last_software_version_downloaded: false,
    date_last_download: false,
  };

  const processRowUpdate = async (newRow, oldRow) => {
    try {
      if (!newRow || !oldRow) {
        console.error("Undefined row data");
        return;
      }

      const response = await axios.put(
        `${process.env.REACT_APP_URL_API}/api/popmetre/${newRow.id}`,
        newRow,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!oldRow.serialNumberPopmetre && newRow.serialNumberPopmetre) {
        try {
          const data = await axios.get(
            `${process.env.REACT_APP_URL_API}/api/generate-presigned-url`
          );
          const url = data.data.url;

          if (url) {
            const email = await axios.get(
              `${process.env.REACT_APP_URL_API}/api/send-email?url=${url}&recipientEmail=${newRow.clientEmail}`
            );
            setDialogData({
              open: true,
              title: "Succès",
              content: `Email envoyé avec succès a ${newRow.clientEmail}`,
              actionText: "Fermer",
              isSuccess: true,
            });
          }
        } catch (emailError) {
          setDialogData({
            open: true,
            title: "Erreur",
            content:
              "Erreur lors de l'envoi du lien. Vérifier l'adresse email du client.",
            actionText: "OK",
            isSuccess: false,
          });
        }
      }
      return response.data;
    } catch (error) {
      setDialogData({
        open: true,
        title: "Erreur",
        content: "Erreur lors de la modification.",
        actionText: "OK",
        isSuccess: false,
      });
      return oldRow;
    }
  };

  const handleDeleteClick = (id) => async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_URL_API}/api/popmetre/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setData((prevRows) => prevRows.filter((data) => data.id !== id));
        setDialogData({
          open: true,
          title: "Succès",
          content: `pOpmetre supprimer avec succès`,
          actionText: "Fermer",
          isSuccess: true,
        });
      } else {
        console.error("Failed to delete the item:", response);
      }
    } catch (error) {
      setDialogData({
        open: true,
        title: "Erreur",
        content: "Erreur lors de la suppression du pOpmetre.",
        actionText: "OK",
        isSuccess: false,
      });
    }
  };

  const handleSendEmailClick = (clientEmail) => async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_API}/api/generate-presigned-url`
      );
      const url = response.data.url;

      if (url) {
        const response = await axios.get(
          `${process.env.REACT_APP_URL_API}/api/send-email?url=${url}&recipientEmail=${clientEmail}`
        );
        setDialogData({
          open: true,
          title: "Succès",
          content: `Email envoyé avec succès a ${clientEmail}`,
          actionText: "OK",
          isSuccess: true,
        });
      }
    } catch (error) {
      setDialogData({
        open: true,
        title: "Erreur",
        content: "Erreur d'envoi du lien, Vérifier l'adresse email du client",
        actionText: "OK",
        isSuccess: false,
      });
    }
  };

  return (
    <Box m='20px'>
      <Header
        title='POPMETRES'
        subtitle='Tableau De Tracabilités des pOpmetres'
      />
      <Box
        m='40px 0 0 0'
        height='75vh'
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            // fontSize: "0.8rem",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.orangeAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.orangeAccent[700],
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
          "& .MuiDataGrid-panelFooter.MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {/* Dialog */}
        <CustomDialog
          open={dialogData.open}
          handleClose={handleCloseDialog}
          title={dialogData.title}
          content={dialogData.content}
          actionText={dialogData.actionText}
          isSuccess={dialogData.isSuccess}
        />

        <DataGrid
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          disableColumnResize={false}
          initialState={{
            columns: {
              columnVisibilityModel: columnVisibilityModel,
            },
          }}
          experimentalFeatures={{ newEditingApi: true }}
          getRowId={(row) => row.id}
          // isCellEditable={true}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={(err) => {
            console.error(err);
          }}
        />
      </Box>
    </Box>
  );
};

export default Popmetres;
