import { useNavigate, useLocation } from "react-router-dom";
import { useContext } from "react";
import { Box, IconButton, useTheme } from "@mui/material";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import useToken from "../../useToken";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const location = useLocation();
  const isNotLoginPage = location.pathname !== '/login';

  const { setToken } = useToken();
  let navigate = useNavigate();

  const handleLogout = () => {
    setToken(null); // Clear the token
    navigate("/login");
  };

  return (
    <Box display='flex' justifyContent='space-between' p={2}>
      <Box display='flex' borderRadius='3px'>
        <img
          alt='logo axelife'
          src={`${process.env.PUBLIC_URL}/axelife.png`}
          style={{
            cursor: "pointer",
            backgroundColor: "transparent",
          }}
        />
      </Box>

      {/* ICONS */}
      <Box display='flex'>
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        {isNotLoginPage && (
          <IconButton onClick={handleLogout}>
            <LogoutIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default Topbar;
