import { Navigate } from 'react-router-dom';
import useToken from '../useToken';

const AuthRedirect = ({ children }) => {
  const { token } = useToken();

  if (token) {
    // Redirect to home if the user is already logged in
    return <Navigate to="/" replace />;
  }

  return children; // Render children if not redirecting
};

export default AuthRedirect;
