import React, { useState } from "react";
import { Step, StepLabel, Stepper, Button, Box } from "@mui/material";
import { useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import CustomDialog from "../../components/CustomDialog";
import { tokens } from "../../theme";
import useToken from "../../useToken";
import axios from "axios";

import Header from "../../components/Header";

const Forum = () => {
  const { token } = useToken();
  const steps = [StepOne, StepTwo, StepThree];
  const [activeStep, setActiveStep] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false); // State for error dialog
  const CurrentStep = steps[activeStep];
  const { validationSchema } = CurrentStep;

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const initialValues = steps.reduce(
    (values, { initialValues: initValues }) => ({
      ...values,
      ...initValues,
    }),
    {}
  );

  const isLastStep = () => activeStep === steps.length - 1;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const submitForm = async (values) => {
    await new Promise((resolve) => setTimeout(resolve, 10));
    try {
      await axios.post(
        `${process.env.REACT_APP_URL_API}/api/popmetre/`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowSuccess(true);
    } catch (error) {
      setShowError(true);
    }
  };

  const handleSubmit = async (values) => {
    isLastStep() ? submitForm(values) : handleNext();
  };

  const handleCloseSuccessDialog = () => {
    setShowSuccess(false);
    window.location.reload();
  };

  const handleCloseErrorDialog = () => {
    setShowError(false);
  };

  return (
    <Box
      m='40px'
      sx={{
        "& label.Mui-focused": {
          color: colors.orangeAccent[400], // change color of label when focused
        },
        "& legend.Mui-focused": {
          color: colors.orangeAccent[400], // change color of label when focused
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: colors.orangeAccent[400], // change border of input
          },
        },
        "& .stepper": {
          "& .MuiStepIcon-root.Mui-completed": {
            color: colors.orangeAccent[400],
          },
          "& .MuiStepIcon-root.Mui-active": {
            color: colors.orangeAccent[400],
          },
        },
      }}
    >
      <Header
        title='AJOUTER POPMETRE'
        subtitle='Ajouter un nouveau pOpmetres a la table de tracabilité'
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <>
            <CustomDialog
              open={showSuccess}
              handleClose={handleCloseSuccessDialog}
              title='Succès'
              content='pOpmetre ajouté avec succès.'
              actionText='OK'
              isSuccess={true}
            />

            <CustomDialog
              open={showError}
              handleClose={handleCloseErrorDialog}
              title='Erreur'
              content="L'ajout de pOpmetre a échoué, vérifier les informations ajoutées."
              actionText='OK'
              isSuccess={false}
            />

            <Stepper
              activeStep={activeStep}
              className='stepper'
              alternativeLabel
            >
              {steps.map(({ title }) => (
                <Step key={title}>
                  <StepLabel>{title}</StepLabel>
                </Step>
              ))}
            </Stepper>

            <Form>
              <CurrentStep values={values} />
              <Box display='flex' justifyContent='end' mt='20px'>
                {activeStep !== 0 && (
                  <Button
                    onClick={handleBack}
                    color='secondary'
                    className='button'
                  >
                    {" "}
                    Retour{" "}
                  </Button>
                )}
                <Button
                  type='submit'
                  variant='contained'
                  color='secondary'
                  className='button'
                >
                  {isLastStep() ? "Ajouter" : "Suivant"}
                </Button>
              </Box>
            </Form>
          </>
        )}
      </Formik>
    </Box>
  );
};

export default Forum;
