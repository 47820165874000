import React, { useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Dialog,
  Button,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const CustomDialog = ({
  open,
  handleClose,
  title,
  content,
  actionText,
  isSuccess,
}) => {
  const dialogStyles = {
    backgroundColor: "#242424",
    color: "white",
  };

  return (
    <Dialog open={open} maxWidth='sm' fullWidth>
      <DialogTitle style={dialogStyles}>{title}</DialogTitle>{" "}
      <DialogContent style={{ marginTop: "24px" }}>
        <DialogContentText>
          {isSuccess ? (
            <CheckCircleIcon
              style={{
                color: "green",
                marginRight: 8,
                verticalAlign: "middle",
              }}
            />
          ) : (
            <CancelIcon
              style={{ color: "red", marginRight: 8, verticalAlign: "middle" }}
            />
          )}
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} style={{ color: "white" }}>
          {actionText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
