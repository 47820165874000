import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import Header from "../../components/Header";

const Login = ({ setToken }) => {
  const navigate = useNavigate();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [loginError, setLoginError] = useState("");

  async function loginUser(credentials) {
    axios
      .post(`${process.env.REACT_APP_URL_API}/api/login/`, credentials)
      .then((response) => {
        setToken(response.data.accessToken);
        navigate("/", { replace: true });
      })
      .catch((error) => {
        setLoginError("La connexion a échoué. Veuillez vérifier vos informations");
      });
  }

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "80vh",
  };
  const boxStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "1rem",
    border: "1px solid #ccc",
    boxShadow: "0 4px 8px #fff", // Shadow effect
    borderRadius: "20px",
    width: "500px",
    backgroundColor: "transparent",
  };

  return (
    <div style={containerStyle}>
      <Box style={boxStyle}>
        <Header title='LOGIN' />
        <Formik
          onSubmit={loginUser}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display='grid'
                gap='30px'
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                  "& label.Mui-focused": {
                    color: colors.orangeAccent[400], // change color of label when focused
                  },

                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: colors.orangeAccent[400], // change border of input
                    },
                  },
                }}
              >
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  label='Nom utilisateur'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.username}
                  name='username'
                  error={!!touched.username && !!errors.username}
                  helperText={touched.username && errors.username}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant='filled'
                  type={showPassword ? "text" : "password"}
                  label='Mot de passe'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password} // Assuming this should be values.password instead of values.lastName
                  name='password'
                  error={!!touched.password && !!errors.password}
                  helperText={touched.password && errors.password}
                  sx={{ gridColumn: "span 4" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={togglePasswordVisibility}
                          edge='end'
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box display='flex' mt='20px'>
                <Button type='submit' color='secondary' variant='contained'>
                  Connexion
                </Button>
              </Box>
              {loginError && <div style={{ color: "red" }}>{loginError}</div>}
            </form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

const checkoutSchema = yup.object().shape({
  username: yup.string().required("Nom utilisateur requis"),
  password: yup.string().required("Mot de passe requis"),
});
const initialValues = {
  username: "",
  password: "",
};

export default Login;
