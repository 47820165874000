import React from "react";
import { Field } from "formik";
import { TextField } from "formik-mui";
import { Grid } from "@mui/material";

import * as Yup from "yup";

const StepThree = () => (
  <Grid container spacing={3}>
    <Grid item xs={12} sm={6}>
      <Field
        type='text'
        id='softwareVersion'
        name='softwareVersion'
        label='Version Logiciel'
        component={TextField}
        disabled={false}
        required
        fullWidth
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <Field
        type='text'
        id='firmwareVersion'
        name='firmwareVersion'
        label='Version Firmware '
        component={TextField}
        disabled={false}
        fullWidth
      />
    </Grid>

    <Grid item xs={12} sm={6}>
      <Field
        type='text'
        id='softwareUpdateDec2020'
        name='softwareUpdateDec2020'
        label='MAJ Logiciel Déc 2020'
        component={TextField}
        disabled={false}
        fullWidth
      />
    </Grid>

    <Grid item xs={12} sm={6}>
      <Field
        type='date'
        id='trainingDate'
        name='trainingDate'
        label="Date de formation à l'usage"
        component={TextField}
        disabled={false}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Grid>

    <Grid item xs={12}>
      <Field
        type='text'
        id='additionalInformation'
        name='additionalInformation'
        label='Informations supplémentaires'
        component={TextField}
        disabled={false}
        fullWidth
      />
    </Grid>
  </Grid>
);

StepThree.title = "Informations Logiciel";

StepThree.initialValues = {
  softwareVersion: "",
  firmwareVersion: "",
  trainingDate: "",
  softwareUpdateDec2020: "",
  additionalInformation: "",
};

StepThree.validationSchema = Yup.object({
  softwareVersion: Yup.string().required("Required"),
  firmwareVersion: Yup.string().required("Required"),
  softwareUpdateDec2020: Yup.string(),
  additionalInformation: Yup.string(),
  trainingDate: Yup.string(),
});

export default StepThree;
