import { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Popmetres from "./scenes/popmetres";
import Form from "./scenes/form";
import Login from "./scenes/login";
import ProtectedRoute from "./components/ProtectedRoute";
import AuthRedirect from "./components/AuthRedirect";
import autoLogout from "./autoLogout";
import useToken from "./useToken";

function App() {
  autoLogout();
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  const { token, setToken } = useToken();

  const location = useLocation(); // Use the useLocation hook to get the current path
  // Determine if Sidebar should be rendered based on path and authentication
  const showSidebar = token && location.pathname !== "/login";

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className='app'>
          {showSidebar && <Sidebar isSidebar={isSidebar} />}{" "}
          <main className={"content" + (showSidebar ? "" : " full-width")}>
            <Topbar setIsSidebar={setIsSidebar} />
            <Routes>
              <Route
                path='/login'
                element={
                  <AuthRedirect>
                    <Login setToken={setToken} />
                  </AuthRedirect>
                }
              />
              <Route element={<ProtectedRoute />}>
                <Route path='/' element={<Popmetres />} />
                <Route path='/popmetres' element={<Popmetres />} />
                <Route path='/addPopmetre' element={<Form />} />
              </Route>
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
