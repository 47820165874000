import React from "react";
import { Field } from "formik";
import { TextField } from "formik-mui";
import { Grid } from "@mui/material";

import * as Yup from "yup";

const StepTwo = () => (
  <Grid container spacing={3}>
    <Grid item xs={12} sm={6}>
      <Field
        type='text'
        id='client'
        name='client'
        label='Client'
        component={TextField}
        disabled={false}
        required
        fullWidth
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <Field
        type='email'
        id='clientEmail'
        name='clientEmail'
        label='Email client'
        component={TextField}
        disabled={false}
        fullWidth
        required
      />
    </Grid>

    <Grid item xs={12} sm={6}>
      <Field
        type='text'
        id='user'
        name='user'
        label='Utilisateur'
        component={TextField}
        disabled={false}
        fullWidth
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <Field
        type='email'
        id='userEmail'
        name='userEmail'
        label='Email utilisateur'
        component={TextField}
        disabled={false}
        fullWidth
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <Field
        type='text'
        id='activity'
        name='activity'
        label='Activité'
        component={TextField}
        disabled={false}
        fullWidth
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <Field
        type='text'
        id='clinicalStudy'
        name='clinicalStudy'
        label='Etude clinique'
        component={TextField}
        disabled={false}
        fullWidth
      />
    </Grid>

    <Grid item xs={12} sm={6}>
      <Field
        type='text'
        id='phone1'
        name='phone1'
        label='N° Téléphone 1'
        component={TextField}
        disabled={false}
        fullWidth
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <Field
        type='text'
        id='phone2'
        name='phone2'
        label='N° Téléphone 2'
        component={TextField}
        disabled={false}
        fullWidth
      />
    </Grid>

    <Grid item xs={12} sm={6}>
      <Field
        type='text'
        id='postalAddress'
        name='postalAddress'
        label='Adresse postale'
        component={TextField}
        disabled={false}
        fullWidth
        required
      />
    </Grid>

    <Grid item xs={12} sm={6}>
      <Field
        type='text'
        id='country'
        name='country'
        label='Pays'
        component={TextField}
        disabled={false}
        fullWidth
        required
      />
    </Grid>
  </Grid>
);

StepTwo.title = "Informations Client";

StepTwo.initialValues = {
  client: "",
  clientEmail: "",
  user: "",
  userEmail: "",
  activity: "",
  phone1: "",
  phone2: "",
  postalAddress: "",
  country: "",
  clinicalStudy: "",
};

StepTwo.validationSchema = Yup.object({
  client: Yup.string().required("Required"),
  clientEmail: Yup.string().required("Required"),
  user: Yup.string(),
  userEmail: Yup.string(),
  activity: Yup.string(),
  phone1: Yup.string(),
  phone2: Yup.string(),
  postalAddress: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  clinicalStudy: Yup.string(),
});

export default StepTwo;
